.loader-container {
    display: flex;
    padding: .5rem .5rem;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background-color: #fff;
    gap: rem;
    margin-top: 20px;
  }
  
  .step-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  
  .circle1 {
    width: 40px;
    height: 40px;
    border: 3px solid #ddd;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
  }
  
  .circle1.active {
    border-color: #C6C6C6;
  }
  
  .circle1.completed {
    background-color: #28a745;
    border-color: #28a745;
    color: white;
  }
  
  .tick {
    font-size: 20px;
  }
  
  .line1 {
    width: 50px;
    height: 3px;
    // background-color: #ddd;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
  
  .line1-completed {
    background-color: #28a745;
  }
  
  .step-name {
    margin-top: 8px;
    font-size: 12px;
    color: #555;
  }
  


















  .loader-container {
    display: flex;
    padding: 2rem;
    padding-bottom: 3rem;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background-color: #f0f0f0;
    position: relative;
    gap: 5rem;
  }
  
  .step-container1 {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .circle1 {
    width: 42px;
    height: 42px;
    border: 8px solid #C6C6C6;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    z-index: 1; /* To ensure the circle is above the line */
  }
  
  .circle1.active {
    border-color: #c6c6c6;
  }
  
  .circle1.completed {
    // background-color: #28a745;
    border-color: #00A94C;
    // background: linear-gradient(to right, #00CE5C, #00A94C);
    background-color: #28a745;
    color: white;
  }
  
  .tick {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 
  
  .line1 {
    width: 7rem;
    height: .6rem;
    // background-color: #ddd;
    position: absolute;
    top: 45%;
    left: -70%;
    transform: translateX(50%);
    z-index: 0; /* To ensure the line is behind the circles */
    // transition: width 3s ease;

    background: linear-gradient(to right, #FFDA61, #0085D8, #44FF15 100%, #ddd 0%); /* Gradient background */
    background-size: 0% 100%; /* Initially empty */
    background-repeat: no-repeat;
    transition: background-size 2s ease; /* Smooth animation */
  }
  
  .line1-completed {
    // background-color: #28a745;
    animation: fillBackground 3s forwards; /* Fill background animation */
  }

  @keyframes fillBackground {
    from {
      // width: 0;
      background-size: 0% 100%;
    }
    to {
      // width: 7rem; 
      background-size: 100% 100%;
    }
  }
  
  .step-name1 {
    // margin-top: 1.2rem;
    // padding: 1rem;
    font-size: .85rem;
    color: #8A8A8A;
    position: absolute;
    top: 55px; /* Position below the circles */
  }
  
