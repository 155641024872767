.amc {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-calendar {
    display: flex;
    justify-content: end;
  }

  &-text {
    position: relative;
    width: 100%;

    &-textarea {
      position: relative;
      width: 100%;
      // direction:rtl;
      height: 12vh;
      display: block;
      max-width: 100%;
      line-height: 1.5;
      padding: 15px 15px 30px;
      border-radius: 3px;
      border: 1px solid #f7e98d;
      transition: box-shadow 0.5s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      background: linear-gradient(#f9efaf, #f7e98d);
      background: -o-linear-gradient(#f9efaf, #f7e98d);
      background: -ms-linear-gradient(#f9efaf, #f7e98d);
      background: -moz-linear-gradient(#f9efaf, #f7e98d);
      background: -webkit-linear-gradient(#f9efaf, #f7e98d);
    }

    &-submit {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &-sql {
    position: relative;
    width: 100%;

    &-textarea {
      position: relative;
      width: 100%;
      // direction:rtl;
      height: 40vh;
      display: block;
      max-width: 100%;
      line-height: 1.5;
      padding: 15px 15px 30px;
      border-radius: 3px;
      border: 1px solid #AAAAAA;
      transition: box-shadow 0.5s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background: linear-gradient(#aff9f5, #b2f78d);
    }

    &-submit {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &-report {
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    text-align: center;
    background: linear-gradient(#eaf9af, #9ff78d);
    align-items: center;
  }
}

.amc {
  position: relative;
  padding: 2rem;
  background-color: #fff;
  height: auto;
  min-height: 100vh;
  border-radius: 1rem;

  /* Top-left AMC Report title */
  .amc-report-title {
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1E1E1E;
  }

  /* Center welcome section */
  .amc-welcome-section {
    text-align: center;
    margin-top: 5.5rem;

    .circular-icon {
      width: 5rem;
      height: 5rem;
      margin: 0 auto 1rem;
      border-radius: 50%;
      // background-color: #eef6fa;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4rem;
      color: #CACACA;
    }

    h2 {
      margin: 0.5rem 0;
      color: #1E1E1E;
    }

    p {
      color: #6b6b6b;
      font-size: 1rem;
    }
  }

  /* Text area box */
  .amc-text-area {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // margin-top: 2rem;

    .input-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap; 


      width: 70%;
      // max-width: 46rem;
      background: #F4F4F4;
      border-radius: 1rem;
      padding: 0.33rem;

      .input-row {
        display: flex;
        align-items: flex-start;
        margin-top: .5rem;
        width: 100%;
        height: auto;

      .input-icon {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 45%;
        background-color: #E4E4E4;
        margin-right: 0.5rem;

        i {
          color: #5E5E5E;
          font-size: 1.2rem;
        }
      }
      .input-icon:hover{
        cursor: pointer;
        background-color: #dddddd;
        opacity: 0.8;
      }

      textarea{
        min-height: fit-content;
        // overflow: hidden;
      }

      .amc-input {
        flex: 1;
        border: none;
        outline: none;
        background: #F4F4F4;
        font-size: 1rem;
        // padding: 0.5rem;
        padding: 0rem 0.5rem;
        color: #333;
        max-height: 25rem;
        resize: none;                 


        &::placeholder {
          color: #aaa;
        }
      }

      .buttons {
        display: flex;
        gap: 0.5rem;

        button {
          border: none;
          padding: 0.5rem 1rem;
          border-radius: .9rem;
          cursor: pointer;
          font-size: 0.9rem;

          &.calendar-btn {
            background-color: #356B8D;
            color: #fff;
          }

          &.execute-btn {
            background-color: #356B8D;
            color: #fff;
            i{
              font-size: 1.2rem; /* Adjust the size as needed */
              vertical-align: middle;
              font-weight: bolder !important;
            }
          }

          &:disabled {
            background-color: #ddd;
            color: #aaa;
            cursor: not-allowed;
          }
        }
      }

    }
    }
  }

// some changes in trial 

 


  /* SQL query box */
  .amc-sql {
    margin-top: 2rem;
    position: relative;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #AAAAAA;
    border-radius: 0.5rem;
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .sql-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-size: 1.3rem;
        color: #1E1E1E;
        font-weight: bold;
        margin: 0;
      }

      .sql-actions {
        display: flex;
        gap: 0.5rem;

        button {
          border: none;
          background: none;
          font-size: 1rem;
          cursor: pointer;
          color: #356B8D;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .sql-content {
      margin-top: 1rem;
      overflow: hidden;
      transition: max-height 0.3s ease;
      max-height: 10rem;
      

      &.expanded {
        transition: transform 0.3 ease;
        // max-height: none;
        max-height: fit-content;
        height: 100%;
      }

      textarea {
        width: 100%;
        // height: 100%;
        // height: fit-content;
        border: none;
        background: none;
        font-family: monospace;
        font-size: 0.9rem;
        color: #333;
        resize: none;
        outline: none;
      }
    }
  }

  /* Calendar section */
  .amc-calendar {
    // margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 6rem;
    height: 1.5rem;
    font-size: small;
  }
  
  /* Loading spinner */
  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.reportTableContainer {
  border: 1px solid #cdcdcd;
  border-radius: .75rem;
  padding: .75rem;
  background-color: #ffff;

  .headers {
    background-color: #f4f4f4;
    border-radius: .75rem;

    .headersBtn {
      display: flex;
      justify-content: end;
      gap: 1rem;
      padding: 1rem;


      .button {
        background-color: #356b8d;
        padding: .25rem 1rem;
        border: none;
        border-radius: 1rem;
        color: #fff;
      }
    }
  }
}



.amc-text-to-sql{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: auto;
}





















//// amc-history



.history-container{
  padding: 2rem;
  background-color: #fff;
  height: auto;
  min-height: 100vh;
  border-radius: 1rem;


  .history-header{
    display: flex;
    gap: .5rem;
    color: #1E1E1E;
    margin-bottom: .5rem;
  }

  .arrow-btn{
    gap: .5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    i{
    color: #0C4A6F;
    }
  }

  .download-history-button {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem .8rem;
    font-size: .82rem;
    border: none;
    border-radius: .8rem;
    background-color: #06486A;
    color: white;
    cursor: pointer;
  }
  
  
  .download-history-button:hover {
    opacity: 0.9;
  }


  .query-box{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    button{
      padding: .4rem .8rem;
      border-radius: 1rem;
    }
  }
  .query-div{
    background-color: #F4F4F4;
    border-radius: 1rem;
    margin-top: 1rem;
    padding: .5rem 1rem;
    font-weight: 400;
    width: 70%;

  }


  .amc-sql {
    margin-top: 2rem;
    position: relative;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #AAAAAA;
    border-radius: 0.5rem;
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .sql-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-size: 1.3rem;
        color: #1E1E1E;
        font-weight: bold;
        margin: 0;
      }

      .sql-actions {
        display: flex;
        gap: 0.5rem;

        button {
          border: none;
          background: none;
          font-size: 1rem;
          cursor: pointer;
          color: #356B8D;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .sql-content {
      margin-top: 1rem;
      overflow: hidden;
      transition: max-height 0.3s ease;
      max-height: 10rem;
      

      &.expanded {
        transition: transform 0.3 ease;
        // max-height: none;
        max-height: fit-content;
        height: 100%;
      }

      textarea {
        width: 100%;
        // height: 100%;
        // height: fit-content;
        border: none;
        background: none;
        font-family: monospace;
        font-size: 0.9rem;
        color: #333;
        resize: none;
        outline: none;
      }
    }
  }
  
}







// report scheduling amc container

.report-schedulingAMC-Container{

  padding: 1rem;
  background-color: #fff;
  height: auto;
  min-height: 100vh;
  border-radius: 1rem;

  .scheduling-header{
    display: flex;
    gap: .5rem;
    color: #1E1E1E;
  }

  .arrow-btn{
    gap: .5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    i{
    color: #0C4A6F;
    }
  }

  .report-container{    
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: .5rem;
    margin-top: 6rem;

    .box{
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    .box1{
      width: 15%;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .box-contain{
      border:1px solid #ccc;
      padding: .5rem;
      border-radius: 1rem;
      width: 60%;

      display: flex;
      align-items: center;

      input{
        border: none;
        width: -webkit-fill-available;
        outline: none;
      }
      .add-btn {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        background-color: #356B8D;
        color: white;
        border: none;
        padding: 0.4rem 1rem;
        gap: .2rem;
        border-radius: 1rem;
        cursor: pointer;
        font-size: 0.9rem;

        i{
          font-size: larger;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
    .box-contain1{
      border:1px solid #ccc;
      padding: .5rem;
      border-radius: 1rem;

      display: flex;
      gap: 1rem;



      div {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        input[type="radio"] {
          appearance: none;
          width: 1rem;
          height: 1rem;
          border: 1px solid #007bff;
          border-radius: 50%;
          outline: none;
          cursor: pointer;
          position: relative;

          &:checked::before {
            content: "";
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            background-color: #007bff;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        label {
          cursor: pointer;
        }
      }
    }


  }

}



.common-btn {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  background-color: #356B8D;
  color: white;
  border: none;
  padding: 0.4rem 1rem;
  margin-top: 7rem;
  gap: .2rem;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 0.9rem;

  &:hover {
    opacity: 0.9;
  }
}






.content {
  transition: transform 1s ease, opacity 0.5s ease;
  transform: translateY(0px); /* Initial position (downwards) */
  opacity: 1; /* Hidden initially */
}

.content-active {
  transform: translateY(-4px); /* Move upwards */
  opacity: 1; /* Fully visible */
  .amc-text-area{
    margin-top: 3rem;
    
  }
}





