.custom-select-container {
    position: relative;
    width: fit-content;
}

.custom-select {
    width: 100%;
    padding: .25rem .25rem .25rem .75rem;
    border: 1px solid #b9b9b9;
    /* border-radius: 4px; */
    appearance: none;
    /* Hide default arrow */
    color: #384D6C;
    outline: none;
    font-size: .85rem;
    background-color: #f5f5f7;
}

.custom-arrow {
    position: relative;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #384D6C;
    color: #384D6C;
    margin-left: 5px;
}