.upload_container {
    display: flex;
    gap: 0.5rem;
}

.custom-read-input {
    border: none;
}

.custom-read-input:focus {
    outline: none;
    outline-width: 0;
}

.custom-input {
    border: none;
    height: fit-content;
    width: fit-content;

}

.custom-input:focus {
    outline: 1px solid #b8b6b6;
    border-radius: 0.2rem;
}

.notification_bar_green {
    background-color: rgba(6, 208, 1, 0.1);
    color: rgba(6, 208, 1, 1);
    padding: 0.3rem 0.4rem 0 0.4rem;
    border-radius: 1rem;
    font-size: 0.85rem;
}

.notification_bar_red {
    background-color: rgba(200, 0, 54, 0.2);
    color: rgba(200, 0, 54, 1);
    padding: 0.3rem 0.4rem 0 0.4rem;
    border-radius: 1rem;
    font-size: 0.85rem;
}

.custom-select5 {
    border: 1px solid #b8b6b6;
    border-radius: 0.2rem;
    height: fit-content;
    width: fit-content;
}

.custom-select5:focus {
    outline: none;
}