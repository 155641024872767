.custom-select-container2 {
    position: relative;
    width: max-content;
    display: flex;
    background-color: #fff;
    border-radius: 1rem;
    gap: 0.5rem;
    padding: 0.25rem;
}

.custom-select2 {
    font-size: .85rem;
    border: none;
    border-radius: 1rem;
    appearance: none;
    outline: none;
}


.arrow {
    /* position: relative;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #384D6C;
    color: #384D6C;
    margin-left: 5px; */
    background-color: rgba(176, 186, 191, 0.2);
    color: rgba(176, 186, 191, 1);
    border-radius: 50%;
    padding: 0.2rem 0.4rem;
    -webkit-text-stroke: 0.5px;
}