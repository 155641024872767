.status-screen {
  padding: 12px;
  background-color: #fff;
  border-radius: 1rem;
  // border: 1px solid #C5C5C5;
}
.status-screen-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 1rem;
  border: 1px solid #C5C5C5;
}

.status-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.step {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.step:hover {
  transform: scale(1.1);
}

.circle {
  width: 20px; /* Reduced size for smaller dots */
  height: 20px;
  // background-color: #007bff;
  background-color:#4F46E5;
  color: white;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  z-index: 1; /* Ensures circle is above the line */
}

.circle_inside{
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-name {
  text-align: center;
  font-size: 14px;
  color: #333;
  margin-top: 10px; /* Adds spacing between the circle and name */
  font-weight: 500;
}

.line {
  flex-grow: 1;
  height: 2px;
  // background-color: #007bff;
  background-color:#4F46E5;
  position: relative;
  z-index: 0; /* Ensures line is behind the circles */
}

.last-step .line {
  display: none;
}

/* Margins for the first and last dots */
.step-container:first-child .circle {
  margin-left: 35px;
}

.step-container:last-child .circle {
  margin-right: 35px;
}

/* Ensure tick is visible only for the last step */
.step:not(.last-step) .circle:before {
  content: "";
}
