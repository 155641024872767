.weekselector {
  position: relative;
  display: inline-block;

  &-input {
    font-size: 0.85rem;
    background-color: #fff;
    // color: rgba(56, 77, 108, 1);
    color: #000000;
    padding: 0rem 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(56, 77, 108, 1)
  }

  &-select {
    position: absolute;
    right: 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 10000;

    justify-content: right;

    &-btn {
      font-size: 1rem;
      padding: 0.4rem;
      border: 1px solid black;

      &--selected {
        background-color: rgba(70, 70, 58, 0.278);
      }
    }
  }

  &-weekpopup {
    position: absolute;
    right: 0;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

  }

  &-year {
    display: flex;
    width: 100%;
    justify-content: space-between;
    -webkit-text-stroke: 0.5px;
    cursor: pointer;
    font-size: 0.75rem;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  &-gridItem {
    border-radius: 0.25rem;
    padding: 0.5rem;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    color: #495057;

    &:hover {
      background-color: #e9ecef;
    }

    &--selected {
      background-color: #e9ecef;

      &:hover {
        background-color: #c2c5c8;
      }
    }
  }



}

.p-calendar {
  font-family: 'Inter', sans-serif;
  max-height: fit-content;
  font-size: 0.85rem;
}

.p-calendar .p-inputtext {
  background-color: #fff;
  color: rgba(56, 77, 108, 1);
  padding: 0rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(56, 77, 108, 1);
}

.p-calendar .p-datepicker {
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 0.85rem;
}