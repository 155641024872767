.custom-select-container3 {
    position: relative;
    width: max-content;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 1rem;
    gap: 2.5rem;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
}

.custom-select3 {
    font-size: 0.85rem;
    color: #838383;
}



.arrow3 {
    background-color: rgba(176, 186, 191, 0.2);
    color: rgba(176, 186, 191, 1);
    border-radius: 50%;
    padding: 0.15rem 0.35rem;
    -webkit-text-stroke: 0.5px;
}

.box_3 {
    z-index: 1;
    min-width: 10vw;
    width: fit-content;
    position: absolute;
    top: 2rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid rgb(245, 245, 247);
    border-radius: 0.5rem;
    height: auto;
    padding: 0.5rem;
    font-size: 0.85rem;
}