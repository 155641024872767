@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&family=Poppins:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Inter:wght@300;400;500;700&display=swap');

.poppins-light {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-bold {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.dashboardsRight {
  width: 85%;
  height: 100vh;
  background-color: #f5f5f7;
  font-family: 'Inter', sans-serif;
  overflow-y: auto;
  padding: 1rem;
}


@media(max-width:930px){
  .dashboardsRight{
    width: 100%;
  }
  // .dashboardsRight.visible{
  //   width: 75%;
  //   margin-left: 250px;
  // }
}