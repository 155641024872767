@mixin mobile($property, $value, $breakpoint) {
  @media (max-width: $breakpoint) {
    #{$property}: $value;
  }
}

// tippy styles

.tippy-box[data-theme~='custom-theme'] {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* Change to your desired background color */
  color: rgb(87, 87, 87);
  /* Adjust text color if needed */
  border-radius: 1rem;
  /* Optional: customize border radius */
  padding: 10px;
  /* Optional: customize padding */
}

.tippy-box[data-theme~='custom-theme'] .tippy-arrow {
  color: #fff;
  /* Match arrow color with background */
}

//////////////

// ag-grid style

.agency-table .ag-header-cell-label {
  justify-content: center;
  text-align: center;
  color: #B4B4B4
}

.agency-table .ag-header-cell-label-hover {
  background-color: #fff !important;
  color: #B4B4B4 !important;
}

.agency-table .ag-header-cell:hover {
  color: unset !important;
  background-color: unset !important;
}

.approve-table .ag-row-cell-hover {
  background-color: unset !important;
  color: unset !important;
}

.center-text {
  display: flex;
  justify-content: center;
}

.brand-cell {
  padding: 0;
}

.email-cell {
  color: rgba($color: #0047FF, $alpha: 0.7);
}


///////////////////////

.userProfileFirstBox {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.userProfileFormBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  @include mobile(flex-direction, column, 400px);
  @include mobile(gap, 1rem, 400px);
}

.userProfileInput {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.userProfilelabel {
  color: #384D6C;
  font-weight: bold;
  font-size: .75rem;
}

.userProfileInputBox {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #384D6C;
  width: 40vw;
  padding: .25rem 1rem;
}

.userStatus {
  color: #2FE346;
  background-color: #2fe3476b;
  padding: 0.25rem 0.75rem;
  font-weight: 700;
  // border-radius: 0rem;
  font-size: 0.75rem;
}

.userStatus1 {
  color: #2FE346;
  background-color: #2fe3476b;
  padding: 0.25rem .5rem;
  font-weight: 700;
  font-size: 0.75rem;
}

// account settings

.accountSettings {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  &-formbox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &-forminput {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &-confirmPass {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #384D6C;
    width: 81vw;
    padding: .25rem 1rem;

    @include mobile(width, 40vw, 400px);

  }

  &-btncontainer {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }
}

// management details page 

// .detailsPage {
//     display: flex;
//     flex-direction: column;
//     gap: 1rem;
//     // padding: .75rem;

//     &-header {
//         display: flex;
//         justify-content: space-between;
//     }

//     &-inputBox {
//         display: flex;
//         border: 1px solid #b9b9b9;
//         border-radius: .5rem;
//         align-items: center;
//         padding-right: .25rem;

//     }

//     &-input {
//         border: none;
//         outline: none;
//         font-size: 0.85rem;
//         color: #384D6C;
//         background-color: #f5f5f7;

//         &::placeholder {
//             color: #384D6C;
//         }
//     }

//     &-btn_box {
//         display: flex;
//         gap: 1rem;
//     }

//     &-box_button {
//         border: none;
//         background-color: #f5f5f7;
//     }

//     &-box_button_active {
//         border-bottom: 2px solid #384D6C;
//         color: #384D6C;
//         background-color: #f5f5f7;
//         font-weight: 750;
//     }
// }
// updated detailsPage

.detailsPage {
  padding: 16px;
  background-color: #f9f9f9;
  /* Light background for better readability */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    gap: 0.5rem;

    &-right{
      display: flex;
      gap: .5rem;
    }
  }

  .search-input-box{
    display: flex;
    background-color: #fff;
    padding: .5rem .45rem;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #ccc;
    align-items: center;
    justify-content: center;
    i{
    font-size: .75rem;
    }
  }

  .invite-box{
    display: flex;
    justify-content: center;
    align-items:center;
  }

  .wrapper-box{
    display: flex;
    align-items: center;
  }

  &-inputBox {  
    display: flex;
    align-items: center;
    border: 1px solid #CDDAE1;
    border-radius: 1rem;
    padding: 4px 8px;
    background-color: #fff;
    width: 280px;
    /* Adjust input width for better usability */

    i {
      color: #06486A;
      font-weight: bold;
      font-size: .75rem;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      padding-left: 8px;
      padding: 0.1rem .3rem;
      font-size: 14px;
      color: #384D6C;
      box-shadow: rgba(0, 0, 0, 0.5);

      &::placeholder {
        color: #E8EAED;
        font-size: .75rem;
      }
    }
  }

  .request-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem .7rem;
    color: #fff;
    font-size: .75rem;
    background-color: #06486A;
    border-radius: 1rem;

    &:hover{
      opacity: 0.9;
    }

  }


  &-btn_box {
    display: flex;
    gap: 3px;
    background-color: #fff;
    border: .5px solid #ccc;
    border-radius: 1rem;
    padding: 0.3rem .5rem;
    box-shadow: rgba(0, 0, 0, 0.5);
  }

  &-box_button {
    border: none;
    padding: 3px 9px;
    font-size: .75rem;
    /* Increased padding for better spacing */
    border-radius: 20px;
    background-color: #fff;
    color: #06486A;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: #bdbdbd;
      /* Slightly darker background on hover */
    }

    &:active {
      background-color: #a3a3a3;
      /* Even darker background on click */
    }

    &.detailsPage-box_button_active {
      background-color: #384D6C;
      color: #FFFFFF;
    }
  }

  @media (max-width: 768px) {
    &-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &-inputBox {
      width: 100%;
    }

    &-btn_box {
      width: 100%;
      justify-content: space-between;
    }

    &-box_button {
      flex-grow: 1;
      text-align: center;
    }
  }
}




// managment details brand page

.brandPage {
  display: flex;
  gap: 2rem;
  align-items: center;

  &-brandList {
    width: 20%;
    height: 72vh;
    border: 1px solid #e0dfdf;
    border-left-color: #FFF;
    overflow-y: scroll;
    background-color: #FFF;
    // padding: 0.5rem 1rem;

  }

  &-brandEach {
    padding: 0.75rem;
    border: 0.5px solid #e0dfdf;
    cursor: pointer;
    background-color: #fff;
    margin: 0.1rem 0;
    display: flex;
    justify-content: center;

  }

  &-brandEachActive {
    padding: 0.75rem;
    border: 2px solid #384D6C;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: center;
  }


  &-brandInGrid {
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1rem;
    font-size: 0.75rem;
    border: 2px solid #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-brandInGridActive {
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1rem;
    font-size: 0.75rem;
    border: 2px solid #384D6C;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-brand {
    display: flex;
    gap: 1rem;
    align-items: center;

  }

  &-grid {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 4rem;
    row-gap: 1rem;
    // gap: 1rem;
    // width: 100%;
    transition: all 0.5s ease;
    padding: 0 2rem;
    justify-content: center;
    align-content: center;
  }
}

.togglebtn {
  border: none;
  background-color: #fafafa;
  color: #b9b9b9;
}

.togglebtnActive {
  color: #384D6C;
}

.hidden-table {
  grid-column: span 5;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.hidden-table.active {
  max-height: 50vh;
  /* Adjust based on table content */
  opacity: 1;
  display: flex;
  justify-content: center;
}


// invite box

.inviteBox {
  display: flex;
  align-items: center;

  &-email {
    border: 1px solid #b9b9b9;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 0 0.5rem;
    font-size: 0.85rem;
    position: relative;
    right: -10px;
    color: #384D6C;
    outline: none;
    background-color: #f5f5f7;

    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;


    &::placeholder {
      color: #A7A7A7;
      font-size: .75rem;
    }
  }

  &-select1 {
    right: -10px;
    z-index: 3;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;

    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;
  }

  &-select2 {
    right: -5px;
    z-index: 2;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  &-select3 {
    right: -10px;
    z-index: 3;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  &-btn {
    background-color: #384D6C;
    color: #fff;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: none;
    font-size: .85rem;
    padding: 0 1rem;

  }

  &-btn2 {
    background-color: #384D6C;
    color: #fff;
    border: none;
    font-size: .85rem;
    padding: 0 1rem;
  }

  &-btnSelect {
    position: relative;
  }

  &-btnOption {
    height: 100%;
    background-color: #384D6C;
    color: #fff;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-left: 1px solid #b9b9b9;
  }

  &-btnOptionBox {
    position: absolute;
    z-index: 1;
    // top: 1.5rem;
    right: 0;
    border: 1px solid #b9b9b9;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #384D6C;
    font-size: 0.85rem;
    width: max-content;
    cursor: pointer;
  }

  &-btnOptionEl {
    &:hover {
      background-color: #e4e4e4;
      color: #384D6C;
    }
  }
}

// drawer

.drawer {
  padding: 2rem;

  &-section2 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &-tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
  }

  &-activeTabs {
    font-weight: 500;
    color: #384D6C;
    border-bottom: 2px solid #384D6C;
  }

  &-subText {
    display: flex;
    flex-direction: column;
  }

  &-brandList {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #C4C4C4;
    padding: 1rem 0rem;
  }
}

.drawerMain {
  font-family: 'Inter', sans-serif;
}


// onboard

.onboard {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &-heading {
    display: flex;
    justify-content: center;
    color: #384D6C;
  }

  &-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &-addBrand {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &-input {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &-addMember {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // overflow-x: auto;
  }

  &-addMemberBtn {
    background-color: #fafafa;
    border: none;
    border-bottom: 1px solid black;
    font-size: 0.75rem;
    font-weight: 700;
    margin-top: 0.5rem;
  }

  &-memberFormUnit {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &-memberForm {
    display: flex;
    gap: 1rem;
  }

  &-uploadfile {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &-imageContainer {
    width: 5rem;
    height: 5rem;
    border: 1px solid #b9b9b9;
    border-radius: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-imageupload {
    border: 2px solid #384D6C;
    padding: 0 0.5rem;
    border-radius: 1rem;
    font-size: 0.85rem;
  }

  &-btnContainer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &-select {
    border-radius: 0.25rem;
  }
}

// modify role select

.modifyRoleSelect {
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


//updated css for 
//user profile


.userProfile {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h6 {
    color: #384d6c;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .userProfileFirstBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .MuiAvatar-root {
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: #000;
      }
    }

    div {
      span {
        display: inline-block;
        margin-right: 10px;
      }

      .userStatus {
        color: green;
        font-size: 0.85rem;
        font-weight: bold;
      }
    }
  }

  hr {
    border: 0;
    height: 1px;
    background: #e0e0e0;
    margin: 20px 0;
  }

  form {
    .userProfileFormBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .userProfileInput {
        flex: 1;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        .userProfilelabel {
          display: block;
          color: #384d6c;
          font-size: 0.9rem;
          margin-bottom: 5px;
        }

        .userProfileInputBox {
          width: 100%;
          padding: 8px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          font-size: 0.9rem;
        }
      }
    }
  }

  .brandPage-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &InGrid {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }
  }

  .submit-btn {
    background-color: #384d6c;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:disabled {
      background-color: #b0b0b0;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: #2c3e50;
    }
  }
}

.subscription_page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;

  &-userDetails {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    // width: 40%;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }

  &-reports {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    // width: 40%;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }

}