@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.nested-table-parent {
  padding: 0%;
}

.table_btn {
  font-family: 'Inter', sans-serif;
}

.p-datatable {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

/* Add more styles as needed */




.column_select-tag {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000000;
  background-color: #F5F5F7;
  /* Background color for the Select Columns area */
  border-radius: 50px;
  padding: 0.25rem 0.55rem;
}

.table_btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #F5F5F7;
  /* Initial icon background color */
  border-radius: 50%;
  /* Circular shape */
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  /* Spacing between buttons */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth transition */
}

.bar-graph {
  background-color: #06486A
}

.table_btn1 i {
  font-size: 16px;
  color: #333;
  /* Initial icon color */
}

.bar-graph i {
  color: #ffffff
}

.table_btn1:hover {
  background-color: #b0b0b0;
  /* Darken the background on hover */
  transform: scale(1.1);
  /* Slightly increase size on hover */
}

.table_btn1:hover i {
  color: #ffffff;
  /* Change icon color on hover */
}


/* Chevron styling */
.column_select-tag i {
  font-size: 12px;
  margin-left: 4px;
  color: #000000;
}


.column_select-tag i {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  /* Smaller circular size */
  height: 24px;
  background-color: #ffffff;
  /* Background for chevron icon */
  border-radius: 50%;
  /* Circular shape */
  margin-left: 4px;
  /* Small space between text and icon */
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #333;
}







//  css for table inner component styling % sign


.percentage-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
}

.arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* Circular background */
  width: 1.19rem;
  /* Explicit width */
  height: 1.19rem;
  /* Explicit height to ensure it's circular */
  font-size: 0.8rem;
  // margin-left: -1px; /* Overlaps slightly with the container border */
  flex-shrink: 0;
}

.percentage-wrap {
  padding-right: .2rem;
}

.arrow-icon {
  font-size: 0.9rem;
  color: inherit;
  /* Matches container text color */
}



// .p-datatable .p-datatable-thead>tr>th,
// .p-datatable .p-datatable-tbody>tr>td {
//   border: 1px solid #E3E3E3;
//   /* Ensure border is defined */
// }

// .p-datatable {
//   border-collapse: collapse;
//   /* Ensures gridlines are visible */
// }



// /* Ensure all table borders are consistent */
// .p-datatable .p-datatable-thead>tr>th,
// .p-datatable .p-datatable-tbody>tr>td,
// .p-datatable .p-datatable-tfoot>tr>td {
//   border: 1px solid #E3E3E3;
//   /* Use a consistent color */
// }

// /* Make gridlines consistent in scrollable tables */
// .p-datatable-scrollable-header-box table,
// .p-datatable-scrollable-body table,
// .p-datatable-scrollable-footer-box table {
//   border-collapse: collapse;
//   /* Ensures uniform borders */
// }

// /* Optional: Set table background for better contrast */
// .p-datatable {
//   background-color: #ffffff;
//   /* Table background */
// }

// /* Uniform color for gridlines inside scrollable tables */
// .p-datatable-scrollable-view {
//   border: 1px solid #E3E3E3;
// }

// /* Remove unnecessary border shading or shadows */
// .p-datatable .p-datatable-header,
// .p-datatable .p-datatable-footer {
//   box-shadow: none;
// }

#unique-table table {
  border-collapse: collapse;
}

#unique-table td, 
#unique-table th {
  border: 1px solid #ddd;
}

#unique-table tbody tr {
  border-bottom: 1px solid #ddd;
}


.non-selectable-row {
  pointer-events: none; /* Prevent interactions like click and hover */
  background-color: #f9f9f9; /* Optional: Dim the row to indicate non-selectable */
  // color: #a0a0a0; /* Optional: Change text color for clarity */
}




// /* Header hover effect */
// .p-sortable-column-header .p-column-title {
//   transition: background-color 0.3s ease;
//   background-color: red; /* Light grey background on hover */
//   color:tomato;
// }

// .p-sortable-column-header:hover .p-column-title {
//   background-color: red; /* Light grey background on hover */
//   color:tomato;
// }

// /* Space between header text and sort icon */
// .p-sortable-column-header .p-column-title {
//   display: inline-flex;
//   align-items: center;
//   gap: 0.5rem; /* Add space between text and sort icon */
// }



.unique-table2{
   .p-sortable-column-icon {
    color: white;
  }
  
  .p-highlight .p-sortable-column-icon {
    color: #003366;
  }
}


