
  .brands-onboarded {
    h3 {
      margin-bottom: 1rem;
      color: #06486a;
      font-weight: 600;
    }
  
    .brand-onboarding-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      justify-content: flex-start;
    }
  
    .brand-card {
      width: 300px;
      background: #dfdfdf;
      // border: 1px solid #e0e0e0;
      border-radius: 1rem;
      padding: .5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  
      .brand-header {
        display: flex;
        align-items: center;
        gap: 1rem;
  
        .brand-logo {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
  
        .brand-details {
          h4 {
            font-size: 1rem;
            margin: 0;
          }
  
          p {
            margin: 0;
            font-size: 0.9rem;
            color: #757575;
          }
        }
      }
  
      .brand-meta {
        margin-top: 1rem;
  
        p {
          margin: 0.5rem 0;
          font-size: 0.9rem;
  
          .platform-logo {
            width: 20px;
            height: 20px;
            margin-left: 0.5rem;
          }
        }
  
        .brand-members {
          display: flex;
          justify-content: space-between;
          font-size: 0.9rem;
        }
  
        .brand-date {
          color: #757575;
          font-size: 0.85rem;
        }
      }
  
      hr {
        margin: 1rem 0;
        border: 0.5px solid #e0e0e0;
      }
  
      .status-button {
        background-color: #dfdfdf;
        color: #757575;
        padding: 0.5rem;
        border: none;
        // border-radius: 4px;
        font-size: 0.9rem;
        cursor: pointer;
        text-align: center;
        padding: 0.15rem 0.5rem;
  
        &:hover {
          color: #bdbdbd;
        }
      }
    }
  
    .add-more-brands {
      margin-top: 2rem;
      font-size: 0.9rem;
      color: #757575;
  
      .add-more-link {
        color: #06486A;
        cursor: pointer;
        // text-decoration: underline;
  
        &:hover {
          color: #05345b;
        }
      }
    }
  }
  