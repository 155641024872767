.svgSize {
  width: 1.25rem;
  height: 1.25rem;
}

.dti-1 {
  stroke-width: 0px;
}

.dti-1,
.dti-2 {
  fill: #878484;
}

.dti-2,
.dti-3 {
  stroke: #878483;
  stroke-miterlimit: 10;
  stroke-width: 91px;
}

.dti-3 {
  fill: none;
}


.mob-1 {
  fill: #878483;
}

.mob-1,
.mob-2 {
  stroke-width: 0px;
}

.mob-2 {
  fill: #fff;
}


.mod-1 {
  fill: #878483;
}

.mod-1,
.mod-2 {
  stroke-width: 0px;
}

.mod-2 {
  fill: #fff;
}


.pm-1 {
  fill: #878483;
}

.pm-1,
.pm-2 {
  stroke-width: 0px;
}

.pm-2 {
  fill: #fff;
}

.sm-1 {
  fill: #878483;
  stroke-width: 0px;
}

.kc-1 {
  fill: #878483;
  stroke-width: 0px;

}

.ktp-1 {
  fill: #878483;
}

.ktp-1,
.ktp-2 {
  stroke-width: 0px;
}

.ktp-2 {
  fill: #fff;
}

.sv-1 {
  fill: #878483;
  stroke-width: 0px;
}

.kai-1 {
  fill: #878483;
}

.kai-1,
.kai-2 {
  stroke-width: 0px;
}

.kai-2 {
  fill: #fff;
}

.vc-1 {
  fill: #878483;
}

.vc-1,
.vc-2 {
  stroke-width: 0px;
}

.vc-2 {
  fill: #fff;
}

.ata-1 {
  fill: #878483;
}

.ata-1,
.ata-2 {
  stroke-width: 0px;
}

.ata-2 {
  fill: #fff;
}

.spc-1 {
  fill: #878483;
}

.spc-1,
.spc-2 {
  stroke-width: 0px;
}

.spc-2 {
  fill: #fff;
}

.ati-1 {
  fill: #878483;
}

.ati-1,
.ati-2 {
  stroke-width: 0px;
}

.ati-2 {
  fill: #fff;
}

.nti-1 {
  fill: #878483;
}

.nti-1,
.nti-2 {
  stroke-width: 0px;
}

.nti-2 {
  fill: #fff;
}