.keyword_cat_container {

    &-header {
        font-size: 20px;
        font-weight: 600;
        padding: 0px 10px 5px 10px;
        background-color: #fff;
        border-left: 5px solid var(--active-color);
        margin-top: 5px;
        width: fit-content;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        &-select {
            width: fit-content;
        }
    }


}

.button-contain {
    display: flex;
    justify-content: end;
}

.keyword-button {
    margin-top: 10px;
    background-color: rgb(59, 124, 149);
    color: #fff;
    border: none;
    border-radius: 5px;
}

.rag-red-outer {
    background: rgba(252, 233, 233, 1);
    // border: 2px solid rgb(231, 115, 115);
}

.dateType{
    background-color: #fff;
    border-radius: 1rem;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    // gap: 0.2rem;
    cursor: pointer;

    &-button{
        font-size: 0.8rem;
        font-weight: 500;
        // font-family: 'inter',sans-serif;
        padding: 0.1rem 0.5rem;
    }
    &-buttonActive{
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.1rem 0.5rem;
        border-radius: 1rem;
        background-color: rgba(205, 218, 225, 1);
        color: rgba(6, 72, 106, 1);
    }
}