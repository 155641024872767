.homeContainer {
  /* background-color: #EEEEEE; */
  flex: 1 1 auto;
}

.authContent {
  height: 100%;
}


.authHeaderRightBrand {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
  padding: 3px 15px 5px 15px;
  border: 1px solid #bdbdbd;
  border-radius: 20px;
  cursor: pointer;
  align-self: center;
}

.authHeaderRightBrand:hover {
  color: #ffffff;
  background-color: #6c757d;
  transition: 0.3s;
}

.authHeaderRightBrandSelect {
  border: 1px solid #bdbdbd;
  border-radius: 20px;
  cursor: pointer;
  /* align-self: center; */
  outline-width: 0;
}

.selectBrandDetails {
  margin: 5px 10px 0 10px;
  padding: 10px;
  background-color: #d9dee4;
  border-radius: 5px;
  /* position: sticky;
    top: 20px;
    z-index: 1;
    overflow-y: hidden; */
}


.currentBrandName {
  background-color: #F5F5F7;
  border-radius: 20px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: .8rem;
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
  margin: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currentBrandNameIcon {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.4rem;
}

// .currentBrandName:hover {
//   background-color: #6c757d;
//   color: #ffffff;
//   transition: 0.3s;
//   border: 1px solid #ffffff;
// }

// .currentBrandName:active {
//   transform: scale(0.95);
// }

.agencyOption {
  min-width: 150px;
  z-index: 199;
  top: 45px;
  /* right: 100px; */
  background-color: #ffffff;
  position: absolute;
  cursor: pointer;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 5px;
}

.brandOption {
  min-width: 230px;
  z-index: 999;
  left: 0.5rem;
  /* top: 45px;
    right: 20px; */
  background-color: #ffffff;
  position: absolute;
  cursor: pointer;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 5px;
}

.searchInputContainer {
  margin: 10px;
}

.brandSearchInputContainer {

  display: flex;
  align-items: center;
  outline-color: #03A0E7;
  border: 1px solid rgba(130, 163, 180, 1);
  border-radius: 1.25rem;
  padding: 0 0.5rem;
}

.brandSearchInput {
  border: none;
  outline: none;
  width: 160px;
  ::placeholder{
    color: rgba(165, 188, 201, 1);
  }
  
}

.selectBrandDetails1 {
  margin: 10px;
  height: fit-content;
  background-color: rgba(6, 72, 106, 0.5);
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.8rem;
}

.brandListContainer {
  overflow-y: auto;
  max-height: 220px;
  margin-bottom: 10px;
}

.brandList- {
  background-color: #ffffff;
  padding: 5px 10px;
  color: #545454;
  font-size: 0.85rem;
}

.brandList-active {
  background-color: #d9dee4;
  padding: 5px 10px;
  color: #545454;
  font-size: 0.85rem;
}

.brandList-:hover {
  background-color: #d9dee4;
}

.selectAgencyandBrand {
  display: flex;
  justify-content: space-between;
}

.agency_select {
  border: 1px solid #bdbdbd !important;
  border-radius: 20px;
  padding: 2px 35px 3px 8px !important;
  cursor: pointer;
  transition: all 0.4s ease;
}

.agency_select:hover {
  background-color: #6c757d;
  color: #ffffff;
  transition: 0.3s;
  border: 1px solid #ffffff;
}

.agency_select_container {
  margin-right: 5px;
  /* position: relative; */
}



/* new css */

.userBox {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.userSubBox {
  display: flex;
  flex-direction: column;
  gap: 0.05rem;
}

.stroke {
  -webkit-text-stroke: 0.5px;
}

.userDown {
  background-color: rgba(176, 186, 191, 0.2);
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
}

.icon_back {
  background-color: rgba(6, 72, 106, 0.2);
  padding: 0.2rem 0.4rem;
  border-radius: 1rem;
}

.icon_sim {
  padding: 0.2rem 0.4rem;
}







/* Hide sidebar and show hamburger for small screens */
@media (max-width: 930px) {
  .dashboardsLeft {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 250px; /* Sidebar width */
      background-color: #ffffff; /* Sidebar background color */
      z-index: 10;
      overflow-y: auto;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      transition: transform 0.3s ease-in-out;
      transform: translateX(-100%);
  }
  .dashboardRight{
    width: 100%;
  }

  // .dashboardsLeft.visible {
  //     display: block;
  //     transform: translateX(0);
  // }

  .hamburger-icon {
      display: block;
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 11;
      cursor: pointer;
      font-size: 24px;
      color: #06486a; /* Icon color */
  }
}

/* Default desktop view */
.dashboardsLeft {
  display: flex;
}

.hamburger-icon {
  display: none;
}







// .backdrop {
//   display: none;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 9;
// }

// .backdrop.visible {
//   display: block;
// }






/* Wrapper container for the layout */
.dashboardContainer {
  display: flex;
  flex-direction: row; /* Maintain horizontal layout */
  width: 100%;
}

/* Sidebar */
.dashboardsLeft {
  // width: 250px; /* Sidebar width in desktop view */
  background-color: #ffffff; /* Sidebar background color */
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.dashboardsLeft.hidden {
  transform: translateX(-100%);
  width: 0; /* Collapse the width */
  display: none; /* Completely hide for small screens */
}

/* Main content area */
.dashboardContent {
  flex: 1;
  width: 100%; /* Take full width when sidebar is hidden */
  transition: margin-left 0.3s ease-in-out;
}

/* Hamburger icon */
.hamburger-icon {
  display: none;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 11;
  cursor: pointer;
  font-size: 24px;
  color: #06486a; /* Icon color */
}

/* Backdrop for overlay when sidebar is open */
// .backdrop {
//   display: none;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 9;
// }

// .backdrop.visible {
//   display: block;
// }

/* Responsive adjustments */
@media (max-width: 930px) {
  .dashboardsLeft {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    // width: 250px;
    z-index: 10;
    overflow-y: auto;
    transform: translateX(-100%);
  }

  
  .hamburger-icon {
    display: block; /* Show the hamburger menu on small screens */
  }
  
  .dashboardContent {
    margin-left: 0; /* Reset margin when sidebar is hidden */
  }
}

