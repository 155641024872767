@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Inter:wght@300;400;500;700&display=swap');

.App {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; */
  /* font-family: 'Oxygen', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Inter', sans-serif;
  
  /* scroll-behavior: smooth; */
}

body {
  font-family: 'Inter', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}







/* ********** Scroll Bar Css *********** */
::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/* ::-webkit-scrollbar:hover {
  height: 10px;
  width: 10px;
} */


::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(197, 197, 197);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(77, 194, 240);
  border-radius: 10px;
}

/* ****************************************** */

:root {
  /* **************** */
  --active-color: #384D6C;
  --secondary-color: #6c757d;

}