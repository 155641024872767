.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  width: calc(100% - 40px);
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  /* border: 1px solid red; */
  border-radius: 8px;
  margin: 10px 20px 0px 20px;
  /* padding: 0 0.5rem; */
}

.agency {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.agencyChangebtn{
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
}

.agencyArrBox{
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.agencyEach{
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.agencyEach:hover{
  background-color: #bdbdbd;
}