@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

// custom platform select
.selectContainer {
    position: relative;
}

.platformSelect {
    border: 2px solid #384D6C;
    padding: 0.2rem 1rem;
    color: #384D6C;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 0.85rem;
}

input[type="checkbox"] {
    accent-color: #384D6C;
    width: 1rem;
    height: 1rem;
}

.selectBox {
    z-index: 999;
    position: absolute;
    width: 12vw;
    height: 20rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    overflow-y: scroll;
    right: 0;
}

.innerSelectBox {
    font-size: 0.85rem;
    display: flex;
    align-items: center;
}


// upload container 
.uploadContainer {
    display: flex;
    gap: 0.5rem;
}

.uploadBtn {
    border: none;
    background-color: rgba(176, 186, 191, 0.2);
    color: rgba(176, 186, 191, 1);
    border-radius: 1rem;
    padding: 0 1rem;
    font-size: 0.85rem;
}

.downloadBtn {
    border: none;
    background-color: rgba(6, 72, 106, 1);
    color: #fff;
    border-radius: 1rem;
    padding: 0 1rem;
    font-size: 0.85rem;

}

.custom-header .p-datatable-thead>tr>th {
    background-color: #fff;
    /* Change this to your desired color */
    /* Change this to your desired text color */
    text-align: center;
    /* Optional: center-align text */
    border: 1px solid #c1c1c1;
    font-size: 0.85rem;
    // font-family: 'Poppins', sans-serif;
    font-family: 'Inter', sans-serif;
}

.custom-cell {
    border: 1px solid #c1c1c1;
    /* Change this to your desired border color */
    font-size: 0.85rem;
    // font-family: 'Poppins', sans-serif;
    font-family: 'Inter', sans-serif;
}

.table_card_new {
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    font-family: 'Inter', sans-serif;
}



// input field excel select design

.file-input-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
}

.file-input {
    display: none;
    /* Hide the default file input */
}

.file-label {
    color: black;
    font-weight: 500;
    cursor: pointer;
    font-size: 0.75rem;

}

#fileName {
    margin-left: 10px;
    font-size: 0.7rem;
    color: rgba(176, 186, 191, 1);
    background-color: rgba(176, 186, 191, 0.2);
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
}

/////////////////////////////
.custom-input2 {
    padding: 0 0.2rem;
    border: none;
    height: min-content;
    width: 100px;

}

.custom-input2:focus {
    outline: 1px solid #b8b6b6;
    border-radius: 0.2rem;
}