@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Inter:wght@300;400;500;700&display=swap');

.inter-light {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.inter-regular {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inter-medium {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.inter-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.dashboardsLeft {
  height: 100vh;
  // max-width: 22%;
  // width: 15%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  font-family: Inter, sans-serif;
  cursor: pointer;
  position: sticky;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(0); /* Default visible position */
  opacity: 1;

  &-logoHeader {
    // margin: 0.5rem;
    height: 16vh;
  }

  &-logo {
    height: 5rem;

    @media (max-width:1200px) {
      height: 4rem;
    }

    @media (max-width:900px) {
      height: 3rem;
    }

    @media (max-width:668px) {
      height: 2rem;
    }
  }

  &-sidebar {
    overflow-y: auto;
    color: rgba(6, 72, 106, 1);
    padding-bottom: 0.75rem;
    padding-top: 0.5rem;
    height: 77vh;
  }

  &-bottom{
    // background-color: #f5f5f7;
    height: 7vh;
    margin: 0 0.5rem;
  }

  &-sidebarUnit {
    padding: 0px 20px;
    margin-top: 10px;
  }

  &-subContainer {
    display: none;
    flex-direction: column;
    gap: 0.2rem;
    padding: 0.2rem 0;
    transition: display 0.3s ease-in-out;

  }

  &-visible {
    display: flex;
  }

  &-heading {
    text-transform: capitalize;
    margin-bottom: 0px;
    font-size: 0.85rem;
    // font-weight: bold;
  }

  &-subHeadingUnit {
    text-decoration: none;
    color: rgba(91, 104, 113, 1);
    padding: 0.4rem 0.4rem;

    &:hover {
      color: rgba(91, 104, 113, 0.8);
    }

    &.active {
      background-color: rgba(205, 218, 225, 1);
      color: rgba(6, 72, 106, 1);
      border-radius: 1rem;
      font-weight: 500;
    }
  }

  &-subHeading {
    font-size: .85rem;
  }


  &-icon {
    transition: transform 0.3s;
  }

  &-up {
    transform: rotate(180deg);
  }

  &-down {
    transform: rotate(0deg);
  }
}





// added for dashboard left bottom

.dashboardsLeft-bottom {
  display: flex;
  align-items: center;
  height: 9vh;
  margin: 0 0.3rem;
  overflow: hidden;
}
.logout-button {
    display: flex;
    justify-content: flex-start;
    padding: .3rem .8rem;
    gap: .1rem;
    font-size: .92rem;
    border: none;
    border-radius: 4px;
    background-color: #06486A;
    color: white;
    cursor: pointer;
  }
   
  .logout-button:hover {
    opacity: 0.9;
  }


.userIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.userBox {
  display: flex;
  align-items: center;
  width: 100%; /* Ensure the box takes full width */
}

.userSubBox {
  display: flex;
  flex-direction: column;
  // margin-left: 10px;
  max-width: 120px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userSubBox span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userDown {
  margin-left: auto;
  display: flex;
  align-items: center;

}







@media (max-width: 930px) {
  .dashboardsLeft {
      // display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      // width: 250px; /* Sidebar width */
      background-color: #ffffff; /* Sidebar background color */
      z-index: 10;
      overflow-y: auto;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
      transform: translateX(-100%);
  }
  .dashboardRight{
    width: 100%;
  }

 .dashboardsLeft.visible {
      display: flex;
      position: absolute;
      width: 22%;
      // width: auto;
      // display: flex;
      // z-index: 10;
      transform: translateX(0);
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;

  }

  .hamburger-icon {
      display: block;
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 11;
      cursor: pointer;
      font-size: 24px;
      color: #06486a; /* Icon color */
  }
}



