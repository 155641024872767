

// reportmenu.scss

.report-menu-footer {
    display: flex;
    justify-content: flex-start; // Align buttons to the left
    gap: 1rem; // Space between buttons
    margin-top: 1rem;
  
    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  
    .publish-button {
      background-color: #06486A;
      color: #fff;
  
      &:hover {
        background-color: #04354e; // Darker shade for hover effect
      }
    }
  
    .clear-button {
      background-color: #ccc;
      color: #5B6871;
  
      &:hover {
        background-color: #aaa;
      }
    }
  }
  


.report_menu_main1 {
    position: relative;
    // top: 20px; /* Adjust as needed */
    // right: 20px; /* Adjust as needed */
    // z-index: 1000;
}
.report_menu-bar{
  color: #000000;
  @media (max-width: 950px) {
    font-size: .75rem; 
  }
  @media (max-width: 1004px) {
    font-size: .75rem; 
  }
}



//   .report-mid button {
//     border-color: #5B6871;
//   }  
  // Button styles for right section slot buttons
  .slot-button {
    border-radius: 8px;
    background-color: white;
    border: 1px solid #D1D1D1;
    color: #5B6871;
    cursor: pointer;
    transition: color 0.3s ease;
    // display: flex;
    justify-content: left;
    display: flex; /* Ensure button content is flexbox */
    justify-content: flex-start; /* Align content to the left */
    align-items: center; /* Vertically center content */
    padding: 0.15rem 0.5rem;
  
    &.disabled {
      color: #B5B5B5; // Faded color for disabled buttons
      border-color: #B5B5B5;
      cursor: not-allowed;
    }
  }

  .report_left-section{
    justify-content: left;
     /* Ensure button content is flexbox */
    justify-content: flex-start; /* Align content to the left */
    align-items: center; /* Vertically center content */
    padding: 0.25rem 0.5rem;
  }

  
  // Reduce spacing around horizontal rules
  .report_menu_main .slot_hr {
    margin: 1.2px 0; // Reduced space above and below hr elements
    color: #130101;
    // border: none;
    border-top: 1px solid; // Light grey for hr
    opacity: none;
  }
  


// report menu footer

.report-menu-footer {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 1rem;
    // margin-left: 2px;
    
  
    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  
    .publish-button {
      background-color: #06486A;
      color: #fff;
  
      &:hover {
        background-color: #04354e; // Darker shade for hover effect
      }
    }
  
    .clear-button {
      background-color: #ccc;
      color: #5B6871;
  
      &:hover {
        background-color: #aaa;
      }
    }
  }
  