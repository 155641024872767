//  rgba(227, 25, 170, 1);
//  rgba(88, 216, 156, 1);
//  rgba(253, 135, 135, 1);
//  rgba(53, 211, 220, 1);
// 


.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-header {
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }
}

// date range style
.dateRange {
  background-color: #fff;
  border-radius: 1rem;
  border: 2px solid rgba(245, 245, 247, 1);
  // padding: 0.4rem .8rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  transition: all 0.3s ease;
  margin-right: .25rem;

  &-btn {
    background-color: #fff;
    border: none;
    // color: rgba(56, 77, 108, 1);
    color: #000000;
    font-weight: 500;
  }

  &-activeBtn {
    border: none;
    // background-color: rgba(56, 77, 108, 1);
    background-color: #CDDAE1;

    color: #06486A;
    border-radius: 1rem;
    font-weight: 500;
  }

  @media (max-width: 950px) {
    font-size: .75rem; // Center tiles on smaller screens
  }

  @media (max-width: 1004px) {
    font-size: .75rem; // Center tiles on smaller screens
  }
}

// card style

.kpi_card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.35rem; // Adjust gap between tiles
  justify-content: space-between;
  // margin-right: .25rem;

  @media (max-width: 768px) {
    justify-content: center; // Center tiles on smaller screens
  }
}

.kpi_card-all {
  margin-right: .25rem;
}

.kpi_card {
  width: 11rem;
  height: 5rem;
  background-color: #fff;
  border: 2px solid rgba(245, 245, 247, 1);
  border-radius: 0.5rem;
  transition: all 0.3s ease;

  &-name,
  &-value {
    font-size: 0.9rem;
  }

  // @media (max-width: 1124px) {
  //     width: 9rem;
  //     height: 4.5rem;

  //     &-name, &-value {
  //         font-size: 0.8rem;
  //     }
  // }

  // @media (max-width: 786px) {
  //     width: 8rem;
  //     height: 4rem;

  //     &-name, &-value {
  //         font-size: 0.75rem;
  //     }
  //     &-namehover {
  //         font-size: 0.75rem;
  //     }
  //     &-v{
  //         font-size: 0.75rem;
  //     }
  // }

  // @media (max-width: 480px) {
  //     width: 7rem;
  //     height: 3.5rem;

  //     &-name, &-value {
  //         font-size: 0.7rem;
  //     }
  // }

  &-name {
    color: rgba(131, 131, 131, 1);
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding: 0.5rem 0.5rem 0 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
    position: relative;
  }

  &-namehover {
    // &:hover {
    //     background-color: rgba(245, 245, 247, 1);
    //     border-radius: 1rem;
    // }
    display: flex;
    justify-content: space-between;
    background-color: rgba(245, 245, 247, 1);
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
  }

  &-icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 0.1rem 0.2rem 0 0.2rem;
  }

  &-value {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-v {
    font-weight: 500;
    font-size: 0.85rem;
  }

  &-p {
    font-size: 0.7rem;
  }

  &-growth {
    color: rgba(20, 186, 109, 1);
    background-color: rgba(20, 186, 109, 0.1);
    border-radius: 1rem;
    padding: 0 0.5rem;
    font-weight: 500;
  }

  &-decline {
    color: rgba(218, 32, 32, 1);
    background-color: rgba(218, 32, 32, 0.1);
    border-radius: 1rem;
    padding: 0 0.5rem;
    font-weight: 500;
  }

  &-select {
    z-index: 1;
    // width: 8vw;
    position: absolute;
    background-color: #fff;
    border: 1px solid rgba(245, 245, 247, 1);
    border-radius: 0.5rem;
    height: 11rem;
    // overflow-y: scroll;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  &-select2 {
    z-index: 1;
    width: 8vw;
    position: absolute;
    background-color: #fff;
    border: 1px solid rgba(245, 245, 247, 1);
    border-radius: 0.5rem;
    height: 15rem;
    // overflow-y: scroll;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  &-selectEl {
    &:hover {
      color: rgba(131, 131, 131, 0.8);
    }
  }


  @media (max-width: 1124px) {
    width: 9rem;
    height: 4.5rem;

    &-name,
    &-value {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 786px) {
    width: 8rem;
    height: 4rem;

    &-name,
    &-value {
      font-size: 0.75rem;
    }

    &-namehover {
      font-size: 0.75rem;
    }

    &-v {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    width: 7rem;
    height: 3.5rem;

    &-name,
    &-value {
      font-size: 0.7rem;
    }
  }

}

// //////////////////////////////////////////////
.table_card {
  border: 2px solid rgba(245, 245, 247, 1);
  border-radius: 0.5rem;
  padding: 0.5rem 0 0 0;

  &-header {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  &-button {
    border-radius: 1rem;
    color: rgba(56, 77, 108, 1);
    background-color: #fff;
    border: 1px solid rgba(56, 77, 108, 1);
    font-size: .85rem;
    padding: 0.1rem 0.5rem;
  }

  &-button_active {
    border-radius: 1rem;
    background-color: rgba(56, 77, 108, 1);
    color: #fff;
    border: 1px solid rgba(56, 77, 108, 1);
    font-size: .85rem;
    padding: 0.1rem 0.5rem;
  }
}

.selected-column {
  background-color: rgba(245, 244, 244, 1) !important;
  cursor: pointer;
}

.p-datatable table .custom-header-class .p-column-header-content {
  text-align: center !important;
  width: 100% !important;
  cursor: pointer !important;

  div {
    span {
      color: #4d17e3 !important;
    }
  }
}

.custom-header-class.percentage div span {
  color: #e35417 !important;
}

// .percentage  span {
//     color: #4d17e3 !important; /* Only for percentage columns */
//     font-weight: bold; /* Optional */
// }

.p-datatable table {
  table-layout: fixed;
  // font-family: 'Poppins', sans-serif;
  font-family: 'Inter', sans-serif;
}

.first-column-style {
  text-transform: uppercase;
  font-weight: 500;

}

.selected-row {
  background-color: rgba(245, 244, 244, 1);
}

.table_btn {
  background-color: #fff;
  border: none;
  border-radius: 1rem;
  color: #5B6871;
  // color: #545454;
  padding: 0.1rem 0.4rem;
  -webkit-text-stroke: 0.3px;

  &:hover {
    background-color: rgba(245, 245, 247, 1);
    padding: 0.1rem 0.4rem;
    border-radius: 0.5rem;
  }
}





// comparison table ///////////////////////////////////////////

.column_select {
  width: fit-content;
  display: flex;
  // top: '-35px';
  // left: '15px';

  &-box {
    position: absolute;
    // top: '-30px';
    // left: '15px';
    z-index: 1;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  &-boxname {
    font-size: 0.85rem;
  }

  &-tag {
    color: rgba(91, 104, 113, 1);
    border-radius: 1rem;
    padding: 0.2rem 0.5rem;
    background-color: rgba(245, 245, 247, 1);
    cursor: pointer;
    font-size: 0.85rem;
  }
}


// achievements style

.mtd_hourly_toggle {
  display: flex;
  gap: 0.5rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.5rem;
  cursor: pointer;

  &-active_btn {
    color: rgba(6, 72, 106, 1);
    background-color: rgba(205, 218, 225, 1);
    font-size: 0.85rem;
    padding: 0rem 0.25rem;
    border-radius: 1rem;
  }

  &-btn {
    color: black;
    font-size: 0.85rem;
    padding: 0rem 0.25rem;
  }
}

// marketing stream styles

.table_kpi_name {
  width: fit-content;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #06486A;
  color: #fff;
  font-size: 0.85rem;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
}

.no-padding {
  padding: 0;

}

.custom-calendar .p-datepicker {
  width: 200px;
  /* Adjust width as needed */
  font-size: 0.85em;
  /* Adjust font size as needed */
}

.custom-calendar .p-datepicker .p-inputtext {
  padding: 0.3em;
  /* Adjust padding as needed */
}

.custom-calendar .p-datepicker .p-datepicker-trigger {
  font-size: 0.75em;
  /* Adjust trigger button size */
}

.metricSelect {
  background-color: #F5F5F7;
  border-radius: 20px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-size: .8rem;
  font-weight: 500;
  // position: relative;
  margin: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric_box {
  z-index: 1000;
  min-width: 10vw;
  width: fit-content;
  position: absolute;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid rgb(245, 245, 247);
  border-radius: 0.5rem;
  height: auto;
  padding: 0.5rem;
  font-size: 0.85rem;
  cursor: pointer;
}

.metricSelectIcon {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.4rem;
}


// product status styling


.product_kpi_box {
  width: 50vw;
  border: 1.5px solid #28A2C4;
  border-radius: 1rem;
  padding: .9rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: .3rem;


  h6 {
    font-size: 1.15rem;
    font-weight: bold;
    color: #333;
    font-weight: bold;
  }

  .status_section,
  .three_div_section {
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    padding: 0.2rem 0;
  }

  .inner_box {
    flex: 1;
    // border: 1px solid #ccc;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    h6 {
      font-size: 1.1rem;
      margin: 0;

    }

    span {
      font-size: .9rem;
    }
  }

  .kpi_with_toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .toggle_button {
      width: 40px;
      height: 20px;
      background-color: red;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 10px;
      position: relative;
      transition: background-color 0.3s ease;

      &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 50%;
        top: 2px;
        left: 2px;
        transition: transform 0.3s ease;
      }

      &.on {
        background-color: green;

        &::before {
          transform: translateX(20px);
          /* Move the white circle to the right */
        }
      }
    }
  }

}

.product_kpi_box2 {
  border: 1.5px solid #e35417;
}

// total sales styling

.totalSalesMain {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.totalSalesTabs {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.3rem 0.4rem;
  width: fit-content;
  font-size: 0.75rem;
  display: flex;
  gap: 0.2rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &-central {
    padding: 0.2rem 0.8rem;
  }

  &-central_active {
    color: #06486A;
    background-color: #CDDAE1;
    border-radius: 1rem;
    padding: 0.2rem 0.8rem;
    font-weight: 500;
  }
}

.group-hover {
  background-color: transparent;
  /* No background by default */
}

/* Hover state */
.group-hover:hover,
.group-hover:hover~.group-hover {
  background-color: #f0f0f0;
  /* Apply the background color on hover */
}

/* Disable row hover effect */
// .p-datatable tr {
//     background-color: transparent !important;
//     transition: none !important;
// }

// /* Apply hover effect only to the specific column */
// .p-datatable .hover-column:hover {
//     background-color: #f0f0f0 !important; /* Change the color as needed */
// }

// /* Apply selection effect only to the specific column */
// .p-datatable .hover-column.p-highlight {
//     background-color: #d0e6fb !important; /* Change the color for selected cell */
// }

// /* Ensure that the text color remains unchanged on selection (optional) */
// .p-datatable .hover-column.p-highlight .p-column-header-content {
//     color: inherit !important;
// }

.center-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

/* Optional: Center text alignment for the entire column */
.p-datatable .p-datatable-tbody>tr>td.center-cell {
  text-align: center !important;
}



.report_menu {
  background-color: #fff;
  cursor: pointer;
  border-radius: 1rem;
  border: 2px solid rgb(245, 245, 247);
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  &-bar {
    background-color: #fff;
    border: none;
    color: rgb(56, 77, 108);
    font-weight: 500;
  }
}










/* Brand Onboarding Module CSS */

.brand-onboarding-container {
  width: 100%;
  height: auto;
  margin: 20px auto;
  padding: 20px;
  // border: 1px solid #ccc;
  background-color: #FFFFFF;
  border-radius: 8px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Logo Upload */
.brand-logo-container {
  display: flex;
  // justify-content: center;
  margin-bottom: 20px;
}

.brand-logo {
  position: relative;
  width: 90px;
  height: 90px;
  border: .5px solid #06486A1A;
  // border: .5px solid #06486A ;

  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  background-color: #06486A1A;
}

.brand-logo:hover {
  border: .5px solid #06486A;
}

.brand-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  border-radius: 50%;
  transition: opacity 0.3s ease;

  display: none;
  align-items: flex-end;
  /* Align at the bottom */
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  backdrop-filter: blur(4px);
  /* Blur effect */

}

.brand-logo:hover .upload-overlay {
  display: flex;
}

/* "Edit" label and icon */
.edit-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 1px solid #06486A;
  width: 100%;
  /* Make it slightly smaller than the circle */
  padding: 6px 10px;
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

.edit-label span {
  font-size: .85rem;
  font-weight: 600;
  color: #06486A;
  margin-right: 4px;
}

.edit-icon {
  width: 13px;
  height: 13px;
  margin-bottom: 8px;
  color: #06486A;

  // background: url('path-to-edit-icon.svg') no-repeat center;
  // background-size: contain;
}

.logo-upload-input {
  display: none;
}

/* Brand Name and Sub-Brand Inputs */


.brand-name-input {
  width: 75%;
  padding: 6px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sub-brand-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sub-brand-input {
  width: 75%;
  // flex-grow: 1;
  padding: 6px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-sub-brand-button,
.remove-sub-brand-button {
  padding: 5px 10px;
  padding: .5rem .7rem;
  font-size: .85rem;
  border: none;
  border-radius: 4px;
  background-color: #06486A;
  color: white;
  cursor: pointer;
}

.remove-sub-brand-button {
  background-color: #dc3545;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 50%;
  // margin-right: 10px;
}

.add-sub-brand-button:hover,
.remove-sub-brand-button:hover {
  opacity: 0.9;
}

/* Dropdowns */
.dropdown-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.dropdown {
  flex: 1;
  position: relative;
}

.dropdown input {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Platform Buttons */
.platform-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.platform-buttons div {
  // flex: 1;
  padding: 10px;
  background-color: #ffffff;
  color: white;
  border: none;
  border-radius: 6px;
  border: 1px solid #EFF2F6;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.platform-buttons div:hover {
  opacity: 0.9;
}

.platform-buttons div span {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 12px;
  height: 12px;
  // background-color: green;
  // border-radius: 50%;
}

/* Product Master Upload */
.product-master {
  margin-bottom: 20px;
}


.product-master-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.product-master-bar input {
  flex: 1;
  margin-right: 10px;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.product-master-bar button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.product-master-bar button:hover {
  opacity: 0.9;
}

.uploaded-file-preview {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
}

.uploaded-file-preview img {
  width: 100%;
  height: auto;
}


.publish-button-container {

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}


/* Publish Button */
.publish-button {

  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  padding: .6rem 1.2rem;
  font-size: .92rem;
  border: none;
  border-radius: 4px;
  background-color: #06486A;
  color: white;
  cursor: pointer;
}


.publish-button:hover {
  opacity: 0.9;
}


/* Dropdown Styling */
.dropdown-container {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.dropdown {
  width: 250px;
  position: relative;
}

.dropdown-search {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.dropdown-item {
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* Scrollbar Styling */
.dropdown-list::-webkit-scrollbar {
  width: 8px;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}





// Competitive Master

.platform-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 15rem;
  padding: .5rem .5rem;
  border-radius: 1.5rem;
}

.platform-display {
  transition: transform 0.3s ease-in-out;
}

button.table_btn_mod {
  margin-left: 0rem;
  width: 32px;
  height: 32px;
}

// .unique-tble .p-datatable-tbody>tr>td {
//   padding: 0;
//   // border-spacing: 0;
//   /* Removes padding from table cells */
// }

.unq-tble .p-datatable-thead {
  display: none;
  /* Hides the entire header row */
}

.unique-tble .p-datatable-row-expansion>td {
  padding: 0 !important;
}















//loaders spinner one



.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #06486A;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//loader animations

.loader-wrapper {
  display: contents;
  align-items: center;
  position: relative;
  width: 90px;
  height: 90px;
}

.loader-svg {
  transform: rotate(-90deg);
  /* Rotate the circle to start at the top */
}

.progress-circle {
  stroke-dasharray: 251.2;
  /* Circumference of the circle */
  stroke-dashoffset: 251.2;
  /* Initially, the circle is not visible */
  animation: progress 2s linear forwards;
}

@keyframes progress {
  0% {
    stroke-dashoffset: 251.2;
    /* Full circle offset */
  }

  100% {
    stroke-dashoffset: 0;
    /* Fully revealed */
  }
}





//style competition master

.download-button {
  display: flex;
  justify-content: center;
  padding: .6rem 1.2rem;
  font-size: .92rem;
  border: none;
  border-radius: 4px;
  background-color: #06486A;
  color: white;
  cursor: pointer;
}


.download-button:hover {
  opacity: 0.9;
}





// Product Harmonization


.product-harmoniz {
  // filter: blur(3px); 
  // pointer-events: none;
  background-Color: "rgba(0, 0, 0, 0.5)";
  z-index: 100000;
}

/* Harmonization Block Styling */
.harmonization-block {
  display: flex;
  background-color: #fff;
  border-radius: 1rem;
  // gap: 0.5rem;
}

/* Product Summary Styling */
.summary-block {
  border: 1px solid #ccc;
  border-radius: 1rem 0 0 1rem;
  /* Rounded on left side only */
  border-inline-end: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #333;
}

/* Match Block Styling */
.match-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #00b007;
  border: 1px solid #00b007;
  border-right: none;
  /* Remove right border */
  padding: 0.5rem;
  border-radius: 0.5rem 0 0 0.5rem;
  /* Rounded on left side only */
  min-width: 60px;
}

.match {
  font-size: .65rem;
}

.unmatch {
  font-size: .65rem;
}

.partial-match{
  font-size: .65rem;
}

.match-button {
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 0.25rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.match-button.active {
  background-color: #00b007;
  color: #fff;
  border-radius: 0.5rem 0 0 0.5rem;
}


// partial-match 

.partial-match-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #9040ff;
  border: 1px solid #9040ff;
  border-left: none;
  border-right: none;
  padding: 0.5rem;
  min-width: 60px;
}

.partial-match-button {
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 0.25rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.partial-match-button.active {
  background-color: #9040ff;
  color: #fff;
}

/* Unmatch Block Styling */
.unmatch-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff4040;
  border: 1px solid #ff4040;
  border-left: none;
  /* Remove left border */
  padding: 0.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
  /* Rounded on right side only */
  min-width: 60px;
}

.unmatch-button {
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 0.25rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.unmatch-button.active {
  background-color: #ff4040;
  color: #fff;
  border-radius: 0rem .5rem .5rem 0rem;
}

/* Adjust for hover/click styles */
.match-button:hover,
.partial-match:hover,
.unmatch-button:hover {
  cursor: pointer;
  opacity: 0.9;
}


.product-harmonization {
  padding: 1rem;
  // background-color: #f9f9f9;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100vh;


  h3 {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .product-cards {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .product-card {
    display: flex;
    background-color: #fff;
    // border: 1px solid #ddd;
    border-radius: 0.5rem;
    overflow: hidden;
    width: 300px;
    height: 120px;
    position: relative;

    .card-section {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: flex-start;
      padding: 1rem;
      margin-bottom: .25rem;


      &.image-section {
        flex: 1;

        img {
          max-width: 100%;
          height: auto;
          border-radius: 0.25rem;
        }
      }

      &.details-section {
        flex: 2;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .platform-icon {
          width: 24px;
          height: 24px;
          margin-bottom: 0.5rem;
        }

        .product-info {
          .product-name {
            font-size: 1rem;
            color: #181818;
            font-weight: bold;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;

          }

          .product-sku {
            font-size: 0.8rem;
            color: #5F5F5F;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .edit-iconback {
              background-color: #EEEEEE;
              border-radius: 50%;
              height: 1.3rem;
              width: 1.3rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .edit-iconback:hover {
              background-color: #bebcbc;
            }

            .edit-icon {
              border: none;
              color: #797979;
              cursor: pointer;
              // font-size: 1rem;
            }
          }
        }
      }

      &.placeholder-section {
        flex: 1;
      }
    }
  }

  .edit-card {
    position: absolute;
    top: 20%;
    right: 30%;
    width: 20rem;
    height: 20rem;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: rgb(255, 255, 255);
    overflow: auto;
    overflow-x: hidden;
    z-index: 1000;



    .search-bar {
      border: 2px dashed #dedede;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin-bottom: .5rem;
      color: #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px; // Ensure search bar doesn't stretch too wide
      margin: 0 auto; // Center the search bar horizontally

      div {
        display: flex;
        align-items: center;
        width: 100%; // Ensures input takes the full width
      }

      .search-icon {
        font-size: 1rem;
        padding-right: 0.5rem;
        color: #dedede; // Match the dashed border color for consistency
      }

      input {
        flex: 1; // Allows input to take the remaining space
        border: none;
        outline: none; // Removes the focus border when clicked
        font-size: 1rem;
        color: #333; // Ensures text is readable
        padding: 0.3rem 0;
        background-color: transparent; // Matches the container's background
      }

      input::placeholder {
        color: #dedede; // Matches the border and icon color
      }
    }


    .close-btn {
      background: none;
      border: none;
      font-size: 1.2rem;
      color: #06486a;
      cursor: pointer;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    .edit-card-content {
      padding: 1rem;

      h4 {
        margin-bottom: 1rem;
      }
    }
  }

}



// new

.product-harmonization1 {

  padding: 1rem;
  margin-top: 1rem;
  // background-color: #f9f9f9;
  // background-color: #fff;
  background-color: #F5F5F7;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 100vh;

  .product-cards {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    /* Three fixed columns for Blinkit, Zepto, and Instamart */
    gap: 1rem;

    .column {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      /* Gap between product cards in the same column */
      h3 {
        text-align: center;
        color: #333;
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }
    }
  }

  .product-card {
    display: flex;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 120px;



    // border: 1px solid #ddd;

    .card-section {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding: 1rem;

      &.image-section {
        flex: 1;

        img {
          max-width: 100%;
          height: auto;
          border-radius: 0.25rem;
        }
      }

      &.details-section {
        flex: 2;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        .product-info {
          .product-name {
            font-size: .8rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
          }
          .product-category {
            font-size: .75rem;
            color: #434343;
            font-weight: 500;
            margin-bottom: 0.3rem;
            margin-top: 0.1rem;
          }

          .product-sku {
            font-size: 0.8rem;
            display: flex;
            justify-content: space-between;

            .edit-iconback {
              background-color: #EEEEEE;
              border-radius: 50%;
              height: 1.3rem;
              width: 1.3rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .edit-iconback:hover {
              background-color: #bebcbc;
            }

            .edit-icon {
              border: none;
              color: #797979;
              cursor: pointer;
              // font-size: 1rem;
            }

          }
        }
      }
    }
  }


  .select-Edit-Card{
    &:hover{
      background-color: #d7d7d7;
      cursor: pointer;
    }
  }

  .vertical-line {
    width: 1px; /* Line thickness */
    height: 100%; /* Full height */
    background-color: #000; /* Line color */
    margin: 0 auto; /* Centering if needed */
  }
  

  .edit-card {
    position: absolute;
    top: 20%;
    right: 30%;
    width: 20rem;
    height: 20rem;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: rgb(255, 255, 255);
    overflow: auto;
    overflow-x: hidden;
    z-index: 1000;



    .search-bar {
      border: 2px dashed #dedede;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin-bottom: .5rem;
      color: #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px; // Ensure search bar doesn't stretch too wide
      margin: 0 auto; // Center the search bar horizontally

      div {
        display: flex;
        align-items: center;
        width: 100%; // Ensures input takes the full width
      }

      .search-icon {
        font-size: 1rem;
        padding-right: 0.5rem;
        color: #dedede; // Match the dashed border color for consistency
      }

      input {
        flex: 1; // Allows input to take the remaining space
        border: none;
        outline: none; // Removes the focus border when clicked
        font-size: 1rem;
        color: #333; // Ensures text is readable
        padding: 0.3rem 0;
        background-color: transparent; // Matches the container's background
      }

      input::placeholder {
        color: #dedede; // Matches the border and icon color
      }
    }


    .close-btn {
      background: none;
      border: none;
      font-size: 1.2rem;
      color: #06486a;
      cursor: pointer;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    .edit-card-content {
      padding: 1rem;

      h4 {
        margin-bottom: 1rem;
      }
    }
  }

}

.powerbiContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .powerbiCard {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-radius: .75rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: #525252;

    &:hover {
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .createdTime {
      color: #969696;
      font-style: italic;
    }

    button {
      border: none;
      background-color: #0a5f8a;
      color: #fff;
      border-radius: 1rem;
      padding: .25rem .75rem;
      font-size: .9rem;
    }
  }
}

.powerbiError{
  display: flex;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #969696;
  margin: 0;
}