.product-kpi-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
  }
  
  .kpi-card {
    flex: 1;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    max-width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
  
  h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
  }
  
  h4 {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #555;
  }
  
  .bar-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .bar {
    display: flex;
    justify-content: space-between;
    background-color: #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
    color: #333;
  }
  