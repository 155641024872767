.notification_main {
    width: 40vw;
    height: 80vh;
    background-color: #f9f9f9;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .notification_heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .notification_tabs {
    display: flex;
    // justify-content: space-around;
    // justify-content: flex-start;
    margin-bottom: 1rem;
  }
  
  .tab_button {
    // flex: 1;
    padding: 0.5rem;
    border: none;
    // background-color: #e0e0e0;
    cursor: pointer;
    border-radius: 0.5rem;
    margin: 0 .5rem;
    margin-right: 1rem;
    font-size: 1rem;
    font-weight:normal;
    color: #05344c;
  
    &.active {
    //   background-color: #384D6C;
      font-weight: bold;
      color: #06486a;
    }
    &.normal{
        color: #5b6871;
    }
  }
  
  .notification_list {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .notification {
    display: flex;
    padding: 0.75rem;
    background-color: white;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
    position: relative;
    cursor: pointer;

    &:hover .notification_hover_actions {
        opacity: 1;
        visibility: visible;
      }
    &.read{
        background-color: #e0e0e0;
    }


  }

  .notification_hover_actions {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 0.5rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .hover_action_button {
    background-color: #06486a;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
  
    &.delete {
      background-color: #e60000;
    }
  
    &.read {
      background-color: #28a745;
    }
  
    &:hover {
      opacity: 0.8;
    }
  }
  
  .notification_icon {
    position: relative;
    margin-right: 1rem;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

     /* User status dot */
  .user_status {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    top: 0;
    right: 0;
    border: 1px solid white;
  }
  
  /* Status colors */
  .user_status.active {
    background-color: green;
  }
  
  .user_status.away {
    background-color: orange;
  }
  
  .user_status.inactive {
    background-color: grey;
  }

  }
  
  .notification_content {
    flex: 1;
    .notification_description {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    .notification_info {
      font-size: 0.75rem;
      color: #888;
    // color:aqua;
      display: flex;
      justify-content: space-between;
    }
  }
  
  .file_attachment {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #384D6C;
    font-weight: bold;
  }
  
  .notification_actions {
    display: flex;
    // justify-content: space-between;
    // min-width: .5rem;
    margin-top: 1em;
  }
  .notification_actions button{
    max-width: 12rem;
  }
  
  .action_button {
    background-color: #06486a;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    flex: 1;
    margin: 0 0.5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
  
    &:hover {
      background-color: #05344c;
    }
    
  }

  .action_button_delete{
    border: none;
    padding: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    // flex: 1;
    margin: 0 .5rem;   
    // text-align: center;
    font-size: 1rem;
    font-weight: normal;
    background-color: #ffffff;
    color: #05344c;
   
    &:hover {
      background-color: rgb(204, 20, 20);
      color: #ffffff;
    } 
  }


  .notification_actions_inline {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }
  
  .action_button_inline {
    background-color: #06486a;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-right: 0.5rem;
  
    &:hover {
      background-color: #05344c;
    }
  }
  
  .action_button_inline.decline {
    
    &:hover {
        color: #ffffff;
      background-color: rgb(204, 20, 20);
    }
  }


  /* File attachment styling */
.file_attachment {
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #f0f4f8;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px; 
  }
  
  .file_details {
    display: flex;
    align-items: center;
    i {
      margin-right: 0.5rem;
      font-size: 1.2rem;
      color: #06486a; /* Icon color */
    }
    p{
        margin-bottom:0;
    }
  }
  
  .file_size {
    font-size: 0.7rem;
    color: #888; /* Grey color for file size */
  }
  
  .download_icon {
    cursor: pointer;
    font-size: 1.2rem;
    color: #06486a; /* Color for download icon */
    &:hover {
      color: #05344c;
    }
  }

  .search_input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    font-size: 1rem;
    outline: none;
  
    &:focus {
      border-color: #06486a;
    }
  }
  