.dashboardRightContent {
    height:100vh;
    overflow-y: auto;
    padding: 1rem;
}


/* ******************* */
/* ag-grid styling */
/* ******************* */
.ag-theme-alpine {
    /* --ag-borders: none; */
    /* --ag-header-background-color: #bdbdbd; */
    /* --ag-foreground-color: #003769; */
    // --ag-header-foreground-color: #B4B4B4;

    --ag-foreground-color: #3A3541;
    --ag-row-border-style: solid;
    --ag-row-border-width: 1px;
    --ag-row-border-color: #E8EDF5;
    // --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);

    --ag-header-column-resize-handle-display: block;
    --ag-header-column-resize-handle-height: 75%;
    --ag-header-column-resize-handle-width: px;
    --ag-header-column-resize-handle-color: #d2d1d1;

}

.ag-theme-alpine .ag-header {
    font-size: 12px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    background-color: #fff;
    color: #B4B4B4 !important;

}

.ag-theme-alpine .ag-row-hover {
    background-color: #f0f0f0;
    /* Change this to your desired hover color */
}

.ag-row .ag-cell {
    display: flex;
    align-items: center;
    /* font-weight: 400; */
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

.ag-root-wrapper {
    border: none;
    /* border-radius: 8px; */
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}



// .tabs_box {
//     display: flex;
//     justify-content: center;
//     cursor: pointer;

//     &-button_first {
//         border: none;
//         padding: 4px 8px;
//         border-top-left-radius: 20px;
//         border-bottom-left-radius: 20px;
//         font-size: 13px;
//         background-color: #D9D9D9;
//         color: #838383;
//     }

//     &-button_mid {
//         border: none;
//         padding: 4px 8px;
//         font-size: 13px;
//         background-color: #D9D9D9;
//         color: #838383;
//     }

//     &-button_last {
//         border: none;
//         padding: 4px 8px;
//         border-top-right-radius: 20px;
//         border-bottom-right-radius: 20px;
//         font-size: 13px;
//         background-color: #D9D9D9;
//         color: #838383;
//     }

//     &-active {
//         background-color: #384D6C;
//         color: #fff;
//         border: none;
//     }
// }


// updated tabs_box css

.tabs_box {
    display: flex;
    justify-content: center;
    cursor: pointer;
    gap: 4px; // Adjust the gap to give equal spaces between buttons

    &-button_first,
    &-button_mid,
    &-button_last {
        border: none;
        padding: 6px 16px; // Increase padding for better spacing
        font-size: 13px;
        background-color: #D9D9D9;
        color: #838383;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    &-button_first {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    &-button_last {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    &-active {
        background-color: #384D6C;
        color: #fff;
        border: none;
    }
}



.cancel-btn {
    border: none;
    color: rgba(176, 186, 191, 1);
    background-color: rgba(176, 186, 191, 0.2);
    padding: .25rem 1rem;
    border-radius: 1.25rem;
}

.submit-btn {
    border: none;
    color: #FFFFFF;
    background-color: rgba(6, 72, 106, 1);
    padding: .25rem 1rem;
    border-radius: 1.25rem;
    
    &:disabled{
        background-color: rgba(8, 75, 109, 0.542);
    }
}

.input-field {
    outline: none;
    border: 1px solid #384D6C;
    border-radius: 0.25rem;
    padding: 0.1rem 0.75rem;
}