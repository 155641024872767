@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&family=Poppins:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Inter:wght@300;400;500;700&display=swap');

.poppins-light {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-bold {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-style: normal;
}

.login {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    font-family: 'Inter', sans-serif;
    position: relative;

    &-label {
        color: #384D6C;
    }

    &-input {
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #384D6C;
        padding: .25rem 1rem;
    }

    &-left {
        width: 50%;
    }

    &-right {
        width: 50%;
    }

    &-loginContainer {
        margin: 3rem 6rem;
        background-color: #fff;
        padding: 0rem 2rem 2rem 2rem;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    &-loginContainerHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-btn {
        border: none;
        color: #fff;
        background-color: var(--active-color);
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
        font-weight: 700;
    }
}

.resendOtpBtn{
    color: #2a3444;
    text-decoration: underline;
    cursor: pointer;
}